import React from "react"
import styled from "styled-components"

const LogoSVG = styled.svg`
  path.letter {
    fill: var(--black);
  }
  @media (prefers-color-scheme: dark) {
    path.letter {
      fill: #ffffff;
    }
  }
`

const Logo: React.FC<LogoProps> = ({ className }: LogoProps) => {
  return (
    <LogoSVG width="140" height="28" viewBox="0 0 140 28" fill="none" className={className}>
      <path
        d="M14.8485 0C6.65 0 0 6.65 0 14.8485V25.4545H4.24242V14.8485C4.24242 8.99394 8.99394 4.24242 14.8485 4.24242C20.703 4.24242 25.4545 8.99394 25.4545 14.8485V25.4545H29.697V14.8485C29.697 6.65 23.047 0 14.8485 0Z"
        fill="#4867FF"
      />
      <path d="M21.2121 25.4545V22.0606L8.48482 22.0606V25.4545L21.2121 25.4545Z" fill="#4867FF" />
      <path d="M21.2121 18.6667V15.2727L8.48482 15.2727V18.6667L21.2121 18.6667Z" fill="#4867FF" />
      <path
        d="M9.21661 11.8788H20.4696C19.409 9.86365 17.2878 8.48486 14.8484 8.48486C12.409 8.48486 10.2878 9.86365 9.21661 11.8788Z"
        fill="#4867FF"
      />
      <path
        className="letter"
        d="M47.6955 22.2409C50.8243 22.347 53.8576 20.9258 55.7455 18.4651L55.7667 18.4333L52.8182 16.0894L52.797 16.1212C51.5985 17.7333 49.9227 18.55 47.9394 18.4757C46.2743 18.4227 44.7682 17.7121 43.697 16.4924C42.647 15.2939 42.1061 13.703 42.1591 12.0061C42.2227 10.3197 42.8803 8.78181 44.0152 7.66817C45.1712 6.54393 46.7197 5.94999 48.3955 6.01363C50.347 6.07726 51.9485 6.99999 53.0515 8.67575L53.0727 8.70757L56.1803 6.59696L56.1591 6.56514C54.4303 3.92423 51.5879 2.34393 48.3849 2.23787C48.2682 2.23787 48.1515 2.22726 48.0349 2.22726C45.5106 2.22726 43.1349 3.1712 41.3 4.9212C39.3803 6.73484 38.2773 9.20605 38.1818 11.8682C38.0864 14.5621 39.0197 17.1182 40.8015 19.0697C42.594 21.0212 45.044 22.1561 47.6955 22.2409Z"
      />
      <path
        className="letter"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M104.141 9.11062C102.688 7.77425 100.736 7.03183 98.6575 7.03183C96.5787 7.03183 94.6272 7.77425 93.1742 9.11062C91.6469 10.5106 90.809 12.4833 90.809 14.6576C90.809 16.8212 91.6469 18.7727 93.1742 20.1727C94.6272 21.5091 96.5787 22.2515 98.6575 22.2515C100.736 22.2515 102.688 21.5197 104.141 20.1727C105.668 18.7727 106.506 16.8106 106.506 14.6576C106.506 12.4833 105.668 10.5106 104.141 9.11062ZM98.6575 18.6773C96.4197 18.6773 94.7333 16.9485 94.7333 14.6576C94.7333 12.3455 96.4197 10.5955 98.6575 10.5955C100.895 10.5955 102.582 12.3349 102.582 14.6576C102.582 16.9485 100.895 18.6773 98.6575 18.6773Z"
      />
      <path
        className="letter"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M114.386 21.753C115.171 22.0712 116.083 22.2515 116.953 22.2515C118.841 22.2515 120.612 21.5303 121.927 20.2152C123.349 18.8046 124.102 16.8849 124.102 14.6576C124.102 10.9561 121.599 7.03183 116.953 7.03183C115.489 7.03183 113.389 7.58334 112.297 9.13183L112 9.55607V7.41365H108.139V27.0349H112V19.7591L112.297 20.1833C112.774 20.8515 113.495 21.3924 114.386 21.753ZM116.158 10.6273C118.364 10.6273 120.145 12.4303 120.145 14.647C120.145 16.8849 118.395 18.6349 116.158 18.6349C114.217 18.6349 112.138 17.1076 112.138 14.647C112.138 12.3985 113.909 10.6273 116.158 10.6273Z"
      />
      <path
        className="letter"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M82.0485 10.7121C82.7167 10.7121 83.3637 10.9136 83.9152 11.2954C84.0955 11.4121 84.2652 11.5606 84.4137 11.7303C84.9864 12.3348 85.3046 13.1303 85.3046 13.9681V21.9545H89.1652V13.9469C89.1864 11.5181 88.5925 9.6833 87.4152 8.49542C86.45 7.51966 85.1031 7.02118 83.4167 7.02118C81.9849 7.02118 80.0864 7.49845 79.1 9.44997L78.7819 10.0121V7.4136H74.9213V21.9651H78.7819V14.4985C78.7819 14.456 78.7819 14.4242 78.7819 14.3818V14.35V13.9788C78.7925 12.1757 80.2455 10.7121 82.0485 10.7121Z"
      />
      <path
        className="letter"
        d="M140 7.41364H136.139V15.1561H136.129C136.033 16.8636 134.602 18.2318 132.873 18.2318C132.48 18.2318 132.088 18.1576 131.717 18.0197C131.027 17.8076 130.455 17.3303 130.083 16.6727C129.776 16.1636 129.606 15.5803 129.606 14.9758V14.6894C129.606 14.6576 129.606 14.6152 129.606 14.5833V7.41364H125.745V15.0182C125.724 17.447 126.318 19.2818 127.495 20.4697C128.461 21.4455 129.808 21.944 131.494 21.944C132.905 21.944 134.75 21.7318 135.8 19.897L135.991 19.5576C135.906 22.0182 134.347 23.8 131.78 23.7682C130.985 23.7894 130.455 23.747 129.532 23.5243C128.853 23.3652 128.185 23.0258 127.517 22.7182L126.159 26.0167C127.983 27.0667 130.189 27.4909 132.427 27.4379C136.596 27.4379 139.841 24.2349 139.968 19.2712V19.25L140 7.41364Z"
      />
      <path
        className="letter"
        d="M68.5681 7.41362V9.03634V9.43937L68.3878 9.1318C67.9212 8.46362 67.0939 7.92271 66.2136 7.55149C65.4288 7.23331 64.5167 7.05301 63.6257 7.05301C61.7379 7.05301 59.9667 7.77422 58.6515 9.08937C57.2303 10.5 56.4773 12.4197 56.4773 14.6469C56.4773 16.8742 57.2303 18.7939 58.6515 20.2045C59.9773 21.5197 61.7379 22.2409 63.6257 22.2409C65.206 22.2409 67.3591 21.6045 68.3878 20.1727L68.5681 19.8757V20.2682V21.9545H72.4287V7.41362H68.5681ZM64.4212 18.6773C62.2045 18.6773 60.4015 16.8742 60.4015 14.6576C60.4015 12.4197 62.1727 10.6697 64.4212 10.6697C66.3621 10.6697 68.4409 12.1969 68.4409 14.6576C68.4409 16.9166 66.6803 18.6773 64.4212 18.6773Z"
      />
    </LogoSVG>
  )
}

interface LogoProps {
  className?: string
}

export default Logo
