import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { Typography } from "@canopyinc/design-library/dist/atoms/Typography/Typography"
import { Button } from "@canopyinc/design-library/dist/atoms/Button/Button"

import SEO from "../components/seo"
import Logo from "../components/Logo"
import Image404 from "../images/404.png"

const { Title, Text } = Typography

const Section = styled.div`
  background-color: #e6f7ff;
  min-height: 100vh;
  padding: 40px;
  text-align: center;

  .ca-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 135px);
    justify-content: center;
    margin: 0 auto;
    max-width: 658px;
    padding-bottom: 24px;

    img {
      max-width: 100%;
      margin: 0 auto 48px;
    }
  }
`

const NotFoundPage: React.FC = () => {
  return (
    <Section>
      <SEO title="Canopy Servicing | 404: Not found" />
      <div>
        <Logo type="dark" className="mx-auto mb-8" />
        <div className="ca-content fs-unmask">
          <img src={Image404} alt="404 Error: Page Not Found" />
          <Title level={3}>404 Error: Page Not Found</Title>
          <Text className="mb-8">The page you are trying to access does not exist.</Text>
          <Link to="/">
            <Button type="primary">Back To Homepage</Button>
          </Link>
        </div>
      </div>
    </Section>
  )
}

export default NotFoundPage
